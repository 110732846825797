<!--
 * @Author: your name
 * @Date: 2021-04-23 15:59:24
 * @LastEditTime: 2021-05-28 15:25:32
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /lpt_edu_pc/src/views/NewsDetail.vue
-->
<template>
	<div>
		<Header :baseInfo="baseInfo"/>
		<div class="before news_detail">
			<div class="width_1200 margin_auto">
				<div class="crumbs">
					<span>您当前的位置：</span>
					<p>
						<!-- <span class="news_center" @click="toNews()">新闻中心</span> -->
						<span v-for="(val , key , index) in article_cate" :key="index">
							<b v-if="index !== 0"> > </b>
						{{val}}</span>
					</p>
				</div>
				<div class="news_container d_flex justify_content_between">
					<div class="news">
						<div class="news_data" v-if="status === 0">
							<h5>{{detail.title}}</h5>
							<p class="source_time">
								<!-- <span>来源：六品书院</span> -->
								<span style="margin-left:30px;">时间：{{detail.updated_at}}</span>
							</p>
							<b></b>
							<div class="video_box" v-if="detail.video_name">
								<video class="video" controls="true" :src="detail.video_name" :autoplay="false"></video>
							</div>
							<div class="news_content rech_text_box" v-html="detail.content"></div>
						</div>
						<div class="news_no_data" v-else-if="status === 4001">
							<img :src="baseImgUrl+'news_no_data.png'" alt="">
							<p>暂无内容</p>
						</div>
					</div>
					<div class="news_link">
						<h5>相关推荐</h5>
						<div>
							<p @click="toDetail(item.id)" class="line_one_clamp" v-for="(item , index) in recommend" :key="index">
								<b></b>
								<span>{{item.title}}</span>
							</p>
						</div>
					</div>
				</div>
				
				<div :class="['btns' , 'd_flex' , prev && next ? 'justify_content_between' : 'justify_content_center'] ">
					<div v-if="prev" class="prev" @click="toDetail(prev.id)" :style="'background:url('+ baseImgUrl +'news_btn_bg.png);background-size:contain;'">
						<h5><span class="first"> > </span>上一篇</h5>
						<p class="line_one_clamp">{{prev.title}}</p>
					</div>
					<div v-if="next" class="next" @click="toDetail(next.id)" :style="'background:url('+ baseImgUrl +'news_btn_bg.png);background-size:contain;'">
						<h5>下一篇<span> > </span> </h5>
						<p class="line_one_clamp">{{next.title}}</p>
					</div>
				</div>
			</div>
		</div>
		<div class="league_container">
			<div :class="[position === 'fixed' ? 'fixed' : 'relative' , 'league_position']">
				<League @openPop="openPop()" :baseInfo="baseInfo"/>
			</div>
		</div>
		<div ref="footer_container">
			<Footer :baseInfo="baseInfo"/>
		</div>
		<Form v-show="isShow" @closePop="closePop()" />
		<Top :baseInfo="baseInfo" v-show="isTopShow" @openPop="openPop()"/>
	</div>
</template>

<script>
import {config} from "../config";
import mixin from '../utils/mixin';
import * as api from "../utils/api";
export default {
	mixins:[mixin],
	components:{
		"Form":()=>import('../components/Form')
	},
	data () {
		return {
			baseImgUrl:config.baseImgUrl,
			recommend:[],
			id:0,
			detail:{},
			status:-1,
			next:'',
			prev:'',
			article_cate:'',
		};
	},

	mounted(){
	
	},

	created(){
		let query = this.$route.query;
		this.id = query.id
		this.getDetail();
	},

	watch:{
		'$route'(to , from){
			if(to.query.id !== from.query.id){
				this.id = to.query.id;
				this.getDetail();
			}
		}
	},

   	methods: {
		toNews(){
			this.$router.push({
				path:'/news'
			})
		},
		getDetail(){
			let params = {
				id : this.id
			}
			api.articleDetail(params).then(res=>{
				this.status = res.status;
				if(res.status === 0){
					this.detail = res.data.detail;
					this.recommend = res.data.article;
					if(JSON.stringify(res.data.next_article) != '{}'){
						this.next = res.data.next_article;
					}else{
						this.next = '';
					}
					if(JSON.stringify(res.data.prev_article) != '{}'){
						this.prev = res.data.prev_article;
					}else{
						this.prev = '';
					}
					if(JSON.stringify(res.data.article_cate) != '{}'){
						this.article_cate = res.data.article_cate;
					}else{
						this.article_cate = ''
					}
					if(this.detail){
						document.title = this.detail.title ? this.detail.title : '新闻详情';
						document.querySelector('meta[name="keywords"]').setAttribute('content', this.detail.keywords ? this.detail.keywords : '');
                    	document.querySelector('meta[name="description"]').setAttribute('content', this.detail.description ? this.detail.description : '');
					}
				}else if(res.status === 4001){
					this.recommend = res.data.article;
				}
			})
		},
		toDetail(id){
			if(id == this.id){
				return
			}
			this.$router.replace({
				path:'/newsDetail',
				query:{
					id:id
				}
			})
		}	   
	}
}

</script>
<style lang='scss' scoped>
	.news_detail{
		background: #F3F3F3;
		// min-height: 1300px;
		padding-bottom: 70px;
		.crumbs{
			text-align: left;
			color:#999999;
			font-size: 18px;
			padding:30px 0 40px 0;
			p{
				display: inline-block;
				b{
					font-weight: normal;
				}
			}
			// .news_center{
			// 	cursor: pointer;
			// }
			// .news_center:hover{
			// 	color:#B91829;
			// }
		}
		.news_container{
			.news{
				width:930px;
				padding:50px;
				background: #fff;
				box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.16);
				min-height: 600px;
				.news_data{
					
					h5{
						font-size: 28px;
						font-weight: bold;
					}
					.source_time{
						color:#999999;
						line-height: 26px;
						font-size:18px;
						margin:20px 0;
					}
					b{
						display: block;
						width:100%;
						height:1px;
						border-bottom:2px dashed #C4C4C4;
					}
					.news_content{
						padding:20px;
						text-align: left;
						p{
							line-height: 28px;
						}
					}
					.video_box{
						width: 500px;
						height: 300px;
						background: #000;
						margin:30px auto 0;
						video{
							width: 100%;
							height: 100%;
						}
					}
				}
				.news_no_data{
					display: flex;
					align-items: center;
					justify-content: center;
					flex-direction: column;
					height: 100%;
					img{
						width:288px;
						height:188px;
					}
					p{
						color: #C4C4C4;
						font-size: 18px;
						margin-top:10px;
					}
				}
			}
			.news_link{
				color:#333;
				width: 240px;
				height:320px;
				box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.16);
				background: #fff;
				padding:10px;
				text-align: left;
				h5{
					line-height: 70px;
					border-bottom:2px dashed #C4C4C4;
					font-size: 20px;
					padding-left:10px;
					font-weight: bold;
				}
				div{
					padding:12px 0;
					p{
						line-height: 32px;
						cursor: pointer;
						b{
							width:8px;
							height:8px;
							border-radius: 4px;
							background: #C4C4C4;
							display: inline-block;
							vertical-align: middle;
						}
						span{
							margin-left:10px;
							vertical-align: middle;
						}
					}
					p:hover{
						color:#B91829;
					}
				}
			}
		}
		
		.btns{
			width:800px;
			margin:50px 0 0 65px;
			.prev,.next{
				cursor: pointer;
				width:350px;
				height: 70px;
				color: #fff;
				font-size: 18px;
				h5{
					font-weight: bold;
					margin-top:8px;
					line-height: 30px;
					.first{
						transform: rotateY(180deg);
						display: inline-block;
					}
				}
				p{
					margin-top:4px;
					padding:0 10px;
					line-height: 20px;
					font-size: 16px;
				}
			}
		}
		.home_title{
			margin:88px 0 20px 0;
		}
		.news_recommend{
			div{
				cursor: pointer;
				width:380px;
				height:90px;
				box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.16);
				border-radius: 5px;
				margin-top:30px;
			}
			div:hover{
				color:#B91829
			}
		}
		.rech_text_box::v-deep img{
			max-width: 660px;
			display: block;
			margin:0 auto;
		}
		.rech_text_box::v-deep p{
			margin-top:10px;
			line-height: 40px;
			text-indent:40px;
			text-align: left;
		}
	}
</style>